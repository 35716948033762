@charset "UTF-8";

// 再生アイコン
.#{$syg-prefix}p-mv-thumb{
	@extend %#{$syg-prefix-ph}ph-mv-thumb;

	&:after{
		font-size: $syg-mv-thumb-font-size;

		border-radius: 50%;
		background-color: $syg-mv-thumb-bgcolor;
		width: $syg-mv-thumb-width;
		height: $syg-mv-thumb-height;

		color: $syg-mv-thumb-color;
		font-family: icomoon;
		content: "\e61d";
		padding-left: .2em;
		padding-top: .1em;
	}
	&:hover{
		background-color: $syg-mv-thumb-bgcolor-hover;
		color: $syg-mv-thumb-color-hover;
	}

	// 画像を拡大させる場合、中に .c-mv-thumb__image を置く
	.#{$syg-prefix}p-mv-thumb__image{
	    display: block;
	    transition: $syg-mv-thumb-img-transition;
	}
	&:hover{
		.#{$syg-prefix}p-mv-thumb__image{
		    transform: $syg-mv-thumb-img-transform;
		}
	}

	// サムネイル上のキャプション
	.#{$syg-prefix}p-mv-thumb__caption{
		position: absolute;
		left: 0;
		bottom: 0;
		box-sizing: border-box;
		width: 100%;
		margin-bottom: 0;
		padding: $syg-mv-thumb-caption-padding;
		background-color: $syg-mv-thumb-caption-bgcolor;
		color: $syg-mv-thumb-caption-color;
		font-size: $syg-mv-thumb-caption-font-size;
	}
}


