@charset "UTF-8";

@mixin create-u-pd( $media ){
	@each $key, $size in $syg-paddings {
		// 全体
		.#{$syg-prefix}u-pd-#{$key}#{$media}{
			padding: $size !important;
		}
		// 上下
		.#{$syg-prefix}u-pd-tb-#{$key}#{$media}{
			padding-top: $size !important;
			padding-bottom: $size !important;
		}
		// 上
		.#{$syg-prefix}u-pd-t-#{$key}#{$media}{
			padding-top: $size !important;
		}
		// 下
		.#{$syg-prefix}u-pd-b-#{$key}#{$media}{
			padding-bottom: $size !important;
		}
		// 左右
		.#{$syg-prefix}u-pd-lr-#{$key}#{$media}{
			padding-left: $size !important;
			padding-right: $size !important;
		}
		// 左
		.#{$syg-prefix}u-pd-l-#{$key}#{$media}{
			padding-left: $size !important;
		}
		// 右
		.#{$syg-prefix}u-pd-r-#{$key}#{$media}{
			padding-right: $size !important;
		}
	}
}

@include create-u-pd( '' );
// タブレット
@include syg-media( $syg-media-tablet ){
	@include create-u-pd( '-tbl' );
}
// スマホ
@include syg-media( $syg-media-mobile ){
	@include create-u-pd( '-sp' );
}


