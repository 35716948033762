@charset "UTF-8";


// 普通のリスト
ul.#{$syg-prefix}p-list{
	// 基本的な共通部分
	@extend %#{$syg-prefix-ph}ph-list;

	padding-left: $syg-list-padding-left;
	> li{
		list-style-type: disc;
		margin-bottom: $syg-list-margin-bottom;
	}
}

// マーク付きリスト
ul.#{$syg-prefix}p-list-mark{
	// 基本的な共通部分
	@extend %#{$syg-prefix-ph}ph-list;

	>li{
		@extend %#{$syg-prefix-ph}ph-list-mark-item;

		&:before{
			left: $syg-list-mark-icon-left;
			top: $syg-list-mark-icon-top;
			content: $syg-list-mark-icon-chara;
			color: $syg-list-mark-icon-color;
		}
	}
}



// ol ////////////////////////////
// 開始番号を指定する場合は style="counter-reset: section 10" のようにする
ol.#{$syg-prefix}p-list{
	@extend %#{$syg-prefix-ph}ph-list;
	@extend %#{$syg-prefix-ph}ph-list-counter;

	> li{
		// 数字部分の共通パーツ
		@extend %#{$syg-prefix-ph}ph-list-counter-item-base;
		@extend %#{$syg-prefix-ph}ph-list-counter-item;
	}
}

// dl ////////////////////////////
dl.#{$syg-prefix}p-list{
	@extend %#{$syg-prefix-ph}ph-list;
	> dd{
		@extend %#{$syg-prefix-ph}ph-list-data-dd;
	}
}


// マーク付きリスト
dl.#{$syg-prefix}p-list-mark{
	// 基本的な共通部分
	@extend %#{$syg-prefix-ph}ph-list;

	> dt{
		@extend %#{$syg-prefix-ph}ph-list-data-dt;
		@extend %#{$syg-prefix-ph}ph-list-mark-item;
		&:before{
			left: $syg-list-mark-icon-left;
			top: $syg-list-mark-icon-top;
			content: $syg-list-mark-icon-chara;
			color: $syg-list-mark-icon-color;
		}
	}
	> dd{
		@extend %#{$syg-prefix-ph}ph-list-data-dd;
		padding-left: $syg-list-mark-padding-left;
	}
}

// タイトル太字
dl.#{$syg-prefix}p-list-bold{
	@extend %#{$syg-prefix-ph}ph-list;
	> dt{
		@extend %#{$syg-prefix-ph}ph-list-data-dt;
		font-weight: bold;
	}
	> dd{
		@extend %#{$syg-prefix-ph}ph-list-data-dd;
	}
}

// インライン
dl.#{$syg-prefix}p-list-inline{
	@extend %#{$syg-prefix-ph}ph-list;
	> dt{
		display: inline-block;
		margin-right: $syg-list-data-inline-margin-right;
	}
	> dd{
		@extend %#{$syg-prefix-ph}ph-list-data-dd;
		display: inline;
		&:after{
			// 改行の為にブロック要素を挿入
			content: "";
			display: block;
		}
	}
}

// 数字付き
dl.#{$syg-prefix}p-list-counter{
	@extend %#{$syg-prefix-ph}ph-list;
	@extend %#{$syg-prefix-ph}ph-list-counter;

	> dt{
		@extend %#{$syg-prefix-ph}ph-list-counter-item-base;
		@extend %#{$syg-prefix-ph}ph-list-counter-item;
	}
	> dd{
		padding-left: $syg-list-counter-padding-left;
		@extend %#{$syg-prefix-ph}ph-list-data-dd;
	}
}
