@charset "UTF-8";

.#{$syg-prefix}l-fx{

	@include display(flex);
	@include syg-media( $syg-media-mobile ){
		@include flex-wrap( wrap );
	}

	// 改行アリ。スマホは強制的に改行モード
	&.#{$syg-prefix}l-fx_wrap{
		@include flex-wrap( wrap );
	}

	/////////////////////////////////////////////
	// 横配置
	/////////////////////////////////////////////
	&.#{$syg-prefix}l-fx_x-l{
		@include justify-content( flex-start );
	}
	&.#{$syg-prefix}l-fx_x-r{
		@include justify-content( flex-end );
	}
	&.#{$syg-prefix}l-fx_x-c{
		@include justify-content( center );
	}
	&.#{$syg-prefix}l-fx_x-btw{
		@include justify-content( space-between );
	}
	&.#{$syg-prefix}l-fx_x-aru{
		@include justify-content( space-around );
	}

	/////////////////////////////////////////////
	// 縦配置
	/////////////////////////////////////////////
	&.#{$syg-prefix}l-fx_y-t{
		@include align-items( flex-start );
	}
	&.#{$syg-prefix}l-fx_y-b{
		@include align-items( flex-end );
	}
	&.#{$syg-prefix}l-fx_y-c{
		@include align-items( center );
	}
	&.#{$syg-prefix}l-fx_y-base{
		@include align-items( baseline );
	}
	&.#{$syg-prefix}l-fx_y-str{
		@include align-items( stretch );
	}

	/////////////////////////////////////////////
	// セル間隔：横
	/////////////////////////////////////////////
	&.#{$syg-prefix}l-fx_mg-0{
		margin-left: 0;
		> [class *= #{$syg-prefix}l-fx__item]{
			padding: 0;
		}
	}

	@each $key, $val in $syg-flex-margins{
		&.#{$syg-prefix}l-fx_mg-#{$key}{
			margin-left: -$val;
			> [class *= #{$syg-prefix}l-fx__item]{
				padding-left: $val;
			}
		}
	}

	/////////////////////////////////////////////
	// セル間隔：縦
	/////////////////////////////////////////////
	&.#{$syg-prefix}l-fx_mgb-0{
		> [class *= #{$syg-prefix}l-fx__item]{
			margin-bottom: 0;
		}
	}

	@each $key, $val in $syg-flex-margins{
		&.#{$syg-prefix}l-fx_mgb-#{$key}{
			margin-top: $val;
			> [class *= #{$syg-prefix}l-fx__item]{
				margin-bottom: $val;
			}
		}
	}

	/////////////////////////////////////////////
	// 可変セル
	/////////////////////////////////////////////
	.#{$syg-prefix}l-fx__item{
		@include flex(1);
	}

	/////////////////////////////////////////////
	// 幅固定セル
	/////////////////////////////////////////////

	// 幅固定共通部分。@extend用。このクラス自体はhtmlから指定しない
	// 幅固定共通部分：PC
	$tmp: '';
	@for $i from 2 through $syg-flex-divide{
		@for $ii from 1 through $i - 1{
			$tmp: $tmp + '.' + $syg-prefix + 'l-fx__item-' + $i + '-' + $ii + ', ';
		}
	}
	#{$tmp}{
		@include flex( none );
	}


	// 幅固定共通部分：スマホ
	$tmp: '';
	@include syg-media( $syg-media-mobile ){
		@for $i from 2 through $syg-flex-divide-sp{
			@for $ii from 1 through $i - 1{
				$tmp: $tmp + '.' + $syg-prefix + 'l-fx__item-' + $i + '-' + $ii + '-sp, ';
			}
		}
		#{$tmp}{
			@include flex( none );
		}
	}

	// サイズ指定：PC 分割なし
	.#{$syg-prefix}l-fx__item-1-1{
		width: 100%;
	}
	// サイズ指定：PC 2分割以上
	@for $i from 2 through $syg-flex-divide{
		@for $ii from 1 through $i - 1{
			.#{$syg-prefix}l-fx__item-#{$i}-#{$ii}{
				width: (100% / $i) * $ii;
			}
		}
	}

	// サイズ指定：スマホ 分割なし
	@include syg-media( $syg-media-mobile ){
		.#{$syg-prefix}l-fx__item-1-1-sp{
			width: 100%;
		}
		// サイズ指定：スマホ 2分割以上
		@for $i from 2 through $syg-flex-divide-sp{
			@for $ii from 1 through $i - 1{
				.#{$syg-prefix}l-fx__item-#{$i}-#{$ii}-sp{
					width: (100% / $i) * $ii;
				}
			}
		}
	}

	/////////////////////////////////////////////
	// オフセット
	/////////////////////////////////////////////

	// PC
	// サイズ指定：PC 2分割以上
	@for $i from 2 through $syg-flex-divide{
		@for $ii from 1 through $i - 1{
			.#{$syg-prefix}l-fx__os-#{$i}-#{$ii}{
				margin-left: (100% / $i) * $ii;
			}
		}
	}

	// スマホ
	@include syg-media( $syg-media-mobile ){
		.#{$syg-prefix}l-fx__os-none-sp{
			margin-left: 0;
		}

		@for $i from 2 through $syg-flex-divide-sp{
			@for $ii from 1 through $i - 1{
				.#{$syg-prefix}l-fx__os-#{$i}-#{$ii}-sp{
					margin-left: (100% / $i) * $ii;
				}
			}
		}
	}
}
