@charset "UTF-8";

// プロポーショナル
.#{$syg-prefix}u-ft-ftr-palt{
	font-feature-settings: 'palt' !important;
}
.#{$syg-prefix}u-ft-ftr-normal{
	font-feature-settings: 'normal' !important;
}

// font-size
@each $key, $val in $syg-font-sizes{
	.#{$syg-prefix}u-ft-sz-#{$key}{
		font-size: #{$val} !important;
	}
}
// color
@each $key, $val in $syg-colors{
	.#{$syg-prefix}u-ft-col-#{$key}{
		color: #{$val} !important;
	}
}

// font-family
.#{$syg-prefix}u-ft-fml-base{
	font-family: $syg-base-font-family !important;
}
.#{$syg-prefix}u-ft-fml-gothic{
	font-family: $syg-font-family-gothic !important;
}
.#{$syg-prefix}u-ft-fml-mincho{
	font-family: $syg-font-family-mincho !important;
}

///////////////////////
// web font

// 源真ゴシック
.#{$syg-prefix}u-ft-fml-genshin{
	@include syg-font-genshin-gothic-exlight;
}
.#{$syg-prefix}u-ft-fml-genshin-b{
	@include syg-font-genshin-gothic-bold;
}
.#{$syg-prefix}u-ft-fml-genshin-h{
	@include syg-font-genshin-gothic-heavy;
}

// 源柔ゴシック
.#{$syg-prefix}u-ft-fml-genju-b{
	@include syg-font-genjyuu-gothic-bold;
}
.#{$syg-prefix}u-ft-fml-genju-h{
	@include syg-font-genjyuu-gothic-heavy;
}

// Lato
// .#{$syg-prefix}u-ft-fml-lato-xt{
// 	@include syg-font-lato-xthin;
// }
// .#{$syg-prefix}u-ft-fml-lato-t{
// 	@include syg-font-lato-thin;
// }
// .#{$syg-prefix}u-ft-fml-lato{
// 	@include syg-font-lato;
// }

// josefinsans
// .#{$syg-prefix}u-ft-fml-jose{
// 	@include syg-font-josefinsans;
// }
// .#{$syg-prefix}u-ft-fml-jose-b{
// 	@include syg-font-josefinsans-bold;
// }

// oswald
// .#{$syg-prefix}u-ft-fml-oswald{
// 	@include syg-font-oswald;
// }






