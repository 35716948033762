@charset "UTF-8";

/*
need Bourbon
*/

@mixin syg-setAnimation( $name, $duration:1s, $delay:0s, $easing:$ease-in-quad, $count:infinite, $mode:none ){
	@include animation-timing-function($easing);
	@include animation-duration($duration);
	@include animation-iteration-count($count);
	@include animation-delay($delay);
	@include animation-name($name);
	@include animation-fill-mode($mode);
}

@mixin syg-bgFadeAnime( $outColor:#000, $inColor:#fff, $duration:0.1s, $easing:linear ){
	background-color: $outColor;
	transition: background-color $duration;

	&:hover{
		background-color: $inColor;
		transition: background-color $duration;
	}
}

@mixin syg-opacityAnime( $opacityIn:0.5, $opacityOut:1, $duration:0.3s, $easing:linear ){
	-webkit-transform: translate3d(0px, 0px, 0px);
	opacity: $opacityOut;
	transition: opacity $duration;

	&:hover{
		opacity: $opacityIn;
		transition: opacity $duration;
	}
}

