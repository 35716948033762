@charset "UTF-8";

$syg-list-wrap-margin-top: 1em !default;
$syg-list-wrap-margin-bottom: 1em !default;

// 通常リスト
$syg-list-padding-left: 2em !default;
$syg-list-margin-bottom: .4em !default;

// オリジナルマークのリスト
$syg-list-mark-padding-left: 1.5em !default;
$syg-list-mark-margin-bottom: .4em !default;
$syg-list-mark-icon-chara: "\e94a" !default;
$syg-list-mark-icon-left: 0 !default;
$syg-list-mark-icon-top: 0 !default;
$syg-list-mark-icon-color: $syg-color-red !default;

// 番号付きリスト
$syg-list-counter-box-width: 2em !default;
$syg-list-counter-box-padding: .5em !default;
$syg-list-counter-padding-left: 2em !default;
$syg-list-counter-margin-bottom: .4em !default;

// データリスト
$syg-list-title-margin-bottom: .2em !default;
$syg-list-data-padding-left: 2.5em !default;
$syg-list-data-margin-bottom: .4em !default;

// データリスト：インライン
$syg-list-data-inline-margin-right: 1em !default;

