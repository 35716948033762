
// メニューウィンドウのアニメーション

@include syg-media($syg-media-mobile){
	.p-sp-menu-popup{
		position: fixed !important;
		z-index: $syg-sp-menu-popup-zindex;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		overflow: hidden;
		-webkit-overflow-scrolling: touch;

		// メニュー初期状態
		&[data-sp-menu='init']{
			height: 0;
			opacity: 0;
		}

		// メニューオープン
		&[data-sp-menu='open']{
			@include syg-setAnimation( sp-menu-popup_open, $duration:.2s, $easing:$ease-out-quad, $count:1, $mode:forwards );
			@include keyframes( sp-menu-popup_open ){
				0%{
					height: 100%;
					opacity: 0;
					@include transform( scale3d( .9, .9, 1 ));
				}
				100%{
					height: 100%;
					opacity: 1;
					@include transform( scale3d( 1, 1, 1 ));
				}
			}
		}
		// メニュークローズ
		&[data-sp-menu='close']{
			@include syg-setAnimation( sp-menu-popup_close, $duration:.2s, $easing:$ease-out-quad, $count:1, $mode:forwards );
			@include keyframes( sp-menu-popup_close ){
				0%{
					height: 100%;
					@include transform( scale3d( 1, 1, 1 ));
					opacity: 1;
				}
				99%{
					height: 100%;
					@include transform( scale3d( .9, .9, 1 ));
					opacity: 0;
				}
				100%{
					height: 0;
					opacity: 0;
				}
			}
		}
	}
}