@charset "UTF-8";


// フォントファミリーの読み込み済みフラグ
$font-setted: true;


// 游ゴシック対策
@font-face{
	font-family: "MyYuGothicM";
	font-weight: normal;
	src:
		local('YuGothic-Medium'),
		local('Yu Gothic Medium'), // chrome
		local('YuGothic-Regular'); // Medium がない
}
@font-face{
	font-family: "MyYuGothicM";
	font-weight: bold;
	src:
		local('YuGothic-Bold'),
		local('Yu Gothic'); // chrome
}

/////////////////////////////////
// web font

// 源真ゴシック
@mixin syg-font-genshin-gothic-exlight{
	font-family: "GenShinGothic-P";
	font-weight: 200;
}
@mixin syg-font-genshin-gothic-bold{
	font-family: "GenShinGothic-P";
	font-weight: 700;
}
@mixin syg-font-genshin-gothic-heavy{
	font-family: "GenShinGothic-P";
	font-weight: 900;
}

// 源柔ゴシック
@mixin syg-font-genjyuu-gothic-bold{
	font-family: "GenJyuuGothic";
	font-weight: 700;
}
@mixin syg-font-genjyuu-gothic-heavy{
	font-family: "GenJyuuGothic";
	font-weight: 900;
}

/////////////////////////////////
// Googleフォント
@mixin syg-font-lato-xthin{
	font-family: 'Lato', sans-serif;
	font-weight: 100;
}
@mixin syg-font-lato-thin{
	font-family: 'Lato', sans-serif;
	font-weight: 300;
}
@mixin syg-font-lato{
	font-family: 'Lato', sans-serif;
	font-weight: 400;
}
@mixin syg-font-josefinsans{
	font-family: 'Josefin Sans', sans-serif;
	font-weight: 400;
}
@mixin syg-font-josefinsans-bold{
	font-family: 'Josefin Sans', sans-serif;
	font-weight: 700;
}
@mixin syg-font-oswald{
	font-family: 'Oswald', sans-serif;
	font-weight: 400;
}
@mixin syg-font-crimson{
	font-family: 'Crimson Text', serif;
	font-weight: 400;
}
@mixin syg-font-lora{
	font-family: 'Lora', serif;
	font-weight: 400;
}


// はんなり明朝
@mixin syg-font-hannari{
	font-family: 'Hannari-sub';
	letter-spacing: 0;
}


// $syg-font-family-gothic : 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
$syg-font-family-gothic : MyYuGothicM, YuGothic, -apple-system, BlinkMacSystemFont, 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;
$syg-font-family-mincho : '游明朝','YuMincho','ヒラギノ明朝 Pro W3','Hiragino Mincho Pro','ＭＳ Ｐ明朝','MS PMincho',serif;


