

$wrapper-min-width: 1100px;
// $wrapper-margin-left: 20px; // 計算用の marginで、実際には設定しない
// $wrapper-margin-right: 20px; // 同上


// $wrapper-navi-width: 260px;
// $wrapper-main-width: $wrapper-min-width - $wrapper-navi-width - $wrapper-margin-left - $wrapper-margin-right;


$content-width: $wrapper-min-width;
$content-padding-sp: 2rem;

// 2ペインの時のメインとサイド
// $content-2pain-main-width: 560px;
// $content-2pain-side-width: 260px;

