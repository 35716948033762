@charset "UTF-8";

.l-content{
	position: relative;
	min-height: 100vh;

	@include syg-media($syg-media-large){
		padding-left: 50px;
		padding-right: 50px;
		min-width: 1100px;
		max-width: 1400px;
		margin: auto;
	}
	@include syg-media($syg-media-mobile){
		padding-top: 2rem;
		padding-bottom: 2rem;
		padding-left: 2rem;
		padding-right: 2rem;
	}
}