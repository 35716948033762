@charset "UTF-8";

// 色
$syg-table-th-color: $syg-base-font-color !default;
$syg-table-td-color: $syg-base-font-color !default;

$syg-table-th-bgcolor: #E6E6E2 !default;
$syg-table-th-bgcolor-odd: #ccc !default;
$syg-table-th-bgcolor-even: #ddd !default;

$syg-table-td-bgcolor: transparent !default;
$syg-table-td-bgcolor-odd: #f6f6f6 !default;
$syg-table-td-bgcolor-even: #fff !default;

$syg-table-border-tb: solid 2px #ccc !default; //表の上下の線
$syg-table-border-inner: solid 1px #ccc !default; // 表の内側の線

// 基本
$syg-table-margin: 1em 0 !default;
$syg-table-padding: 0.4em !default;

