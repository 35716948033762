@charset "UTF-8";

// 袋文字
@mixin syg-text-fukuromoji( $color, $width, $strong ){
	text-shadow:
		0 (-$width) $strong $color,
		$width (-$width) $strong $color,
		$width 0 $strong $color,
		$width $width $strong $color,
		0 $width $strong $color,
		(-$width) $width $strong $color,
		(-$width) 0 $strong $color,
		(-$width) (-$width) $strong $color;
}

@mixin syg-text-justify{
    text-align: justify;
    text-justify: inter-ideograph;
}