@charset "UTF-8";

// 注釈（※）リストの共通部分
%#{$syg-prefix}p-notice-common{
	margin-top: $syg-base-margin-bottom;
	margin-bottom: $syg-base-margin-bottom;
}

// 注釈（※）リストアイテムの共通部分
%#{$syg-prefix}p-notice-item-common{
	text-indent: -1em;
	margin-left: 1em;
	// line-height: 1.5em;
	list-style-type: none;
}

p.#{$syg-prefix}p-notice{
	@extend %#{$syg-prefix}p-notice-common;
	@extend %#{$syg-prefix}p-notice-item-common;
}

ul.#{$syg-prefix}p-notice{
	@extend %#{$syg-prefix}p-notice-common;
	li{
		@extend %#{$syg-prefix}p-notice-item-common;
	}
}


