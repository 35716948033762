
// 再生ボタンつきリンク

$syg-mv-thumb-width: 35px;
$syg-mv-thumb-height: 35px;

$syg-mv-thumb-font-size: 20px;
$syg-mv-thumb-color: #fff;
$syg-mv-thumb-color-hover: #fff;

$syg-mv-thumb-bgcolor: transparent;
$syg-mv-thumb-bgcolor-hover: transparent;

// 中にいれた画像を拡大させる場合
$syg-mv-thumb-img-transition: .3s ease;
$syg-mv-thumb-img-transform: scale(1.05);

// サムネイル内キャプション設定
$syg-mv-thumb-caption-bgcolor: rgba(0, 0, 0, 0.4);
$syg-mv-thumb-caption-font-size: $syg-font-size-s;
$syg-mv-thumb-caption-color: #fff;
$syg-mv-thumb-caption-padding: 0.3em 0.5em;

