@charset "UTF-8";


html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i, a,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, main, section, summary,
time, mark, audio, video {
margin:0;
padding:0;
border:0;
outline:0;
font-size:100%;
vertical-align:baseline;
background:transparent;
box-sizing: border-box;
	&:before,
	&:after{
		box-sizing: border-box;
	}
}

body {
line-height:1;
-webkit-text-size-adjust: 100%;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section,main {
display:block;
}

nav ul{
list-style:none;
}

li{
	list-style: none;
}

blockquote, q {
quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
content:'';
content:none;
}

a {
// margin:0;
// padding:0;
// font-size:100%;
// vertical-align:baseline;
// background:transparent;
}


img{
	border: none;
	vertical-align: middle;
}

ins {
text-decoration:none;
}

mark {
font-style:italic;
font-weight:bold;
}

del {
text-decoration: line-through;
}

abbr[title], dfn[title] {
border-bottom:1px dotted;
cursor:help;
}

table {
border-collapse:collapse;
border-spacing:0;
table-layout: auto;
}

hr {
display:block;
height:1px;
border:0;
border-top:1px solid #cccccc;
margin:1em 0;
padding:0;
}

input, select {
vertical-align:middle;
}

///////////////////////////////////
// 見だし内は line-height 個別指定
h1, h2, h3, h4, h5, h6{
	line-height: 1.4;
}



