@charset "UTF-8";

// JSと連動してスクロールインでフェードインするクラス

/////////////////
.#{$syg-prefix}p-iv{
	opacity: 0;

	// フェードイン開始時に付与する。
	&.#{$syg-prefix}p-iv_in{
		opacity: 1;
		transition: all $syg-inView-time $ease-out-quad;
	}

	// 動きを指定
	@each $key, $val in $syg-inView-move{
		&.#{$syg-prefix}p-iv_#{$key}{
			@include transform( nth($val,1) );
			&.#{$syg-prefix}p-iv_in{
				transform: nth($val,2);
			}
		}
	}

	// 遅延を指定
	@each $key, $val in $syg-inView-delay{
		&.#{$syg-prefix}p-iv_dl-#{$key}{
			transition-delay: $val;
		}
	}
}

