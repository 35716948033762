@charset "UTF-8";


.l-footer{
	width: 100%;
	background-color: #000;
	padding-top: 2rem;
	padding-bottom: 4rem;
	padding-left: 1rem;
	padding-right: 1rem;

	.l-footer__copyright{
		text-align: center;
		font-size: $syg-font-size-xs;
		color: #fff;
		margin-bottom: 1rem;
	}

	.l-footer__banners{
		@include display(flex);
		@include justify-content(center);
		@include flex-wrap(wrap);
		margin-left: -4px;
		margin-bottom: 1rem;

		li{
			@include flex(none);
			padding-left: 4px;
			margin-bottom: 4px;
			@include syg-media($syg-media-large){
				width: 200px;
			}
			@include syg-media($syg-media-mobile){
				width: 38vw;
			}
		}

		img{
			width: 100%;
			height: auto;
		}
	}
	.l-footer__social{
		@include justify-content(center);
		margin-bottom: 2rem;
	}
}


