@charset "UTF-8";

// シェアボタンのラッパー。丸にするか四角にするかはここで決める
// <div class="c-share-circle">
// <a class="c-share__tw"></a>
// </div>

// 共通部分
%#{$syg-prefix}c-share-common{
	@include display( flex );

	[class*="#{$syg-prefix}p-share-btn-"]{
		margin: $syg-share-btn-margin;
	}
}

// 丸ボタン
.#{$syg-prefix}c-share-circle{

	@extend %#{$syg-prefix}c-share-common;

	[class*="#{$syg-prefix}p-share-btn-"]{
		width: $syg-share-circle-size-pc;
		height: $syg-share-circle-size-pc;
		font-size: $syg-share-circle-font-size-pc;
		border-radius: 50%;
		@include syg-media($syg-media-mobile){
			width: $syg-share-circle-size-sp;
			height: $syg-share-circle-size-sp;
			font-size: $syg-share-circle-font-size-sp;
		}
	}
}

// 箱ボタン
.#{$syg-prefix}c-share-box{

	@extend %#{$syg-prefix}c-share-common;

	[class*="#{$syg-prefix}p-share-btn-"]{
		border-radius: $syg-share-box-border-radius;
		width: $syg-share-box-w-pc;
		height: $syg-share-box-h-pc;
		font-size: $syg-share-box-font-size-pc;
		@include syg-media($syg-media-mobile){
			width: $syg-share-box-w-sp;
			height: $syg-share-box-h-sp;
			font-size: $syg-share-box-font-size-sp;
		}
	}
}


