@charset "UTF-8";

// 上下パディング
$syg-padding-xs: 4px !default;
$syg-padding-s: 8px !default;
$syg-padding-n: 16px !default;
$syg-padding-m: 24px !default;
$syg-padding-l: 32px !default;
$syg-padding-xl: 48px !default;

$syg-paddings: (
	none: 0,
	xs: $syg-padding-xs,
	s: $syg-padding-s,
	n: $syg-padding-n,
	m: $syg-padding-m,
	l: $syg-padding-l,
	xl: $syg-padding-xl
) !default;