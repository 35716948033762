@charset "UTF-8";

/////////////////
// display 属性

.#{$syg-prefix}u-dp-blk{
	display: block !important;
}

.#{$syg-prefix}u-dp-ib{
	display: inline-block !important;
}

.#{$syg-prefix}u-dp-in{
	display: inline !important;
}
