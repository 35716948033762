
// テキスト
textarea,
input[type="text"],
input[type="email"]{
	margin: $syg-margin-s 0;
    padding: .5em;
    border: solid 1px #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: $syg-font-size-n;
    box-shadow: 1px 1px 2px 0 rgba(0,0,0,.2) inset;

    &:focus{
	    box-shadow:
	    	0 0 4px 4px rgba($syg-color-aqua,.5),
	    	1px 1px 2px 0 rgba(0,0,0,.2) inset;
    }
}

// 送信ボタン
input[type="submit"]{
	@extend .#{$syg-prefix}p-btn;
}
