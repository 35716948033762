
// スマホ用ハンバーガーメニュー

.#{$syg-prefix}p-sp-menu-btn{
	position: $syg-sp-menu-btn-position;
	z-index: $syg-sp-menu-btn-zindex;
	display: inline-block;
	width: $syg-sp-menu-btn-width;
	height: $syg-sp-menu-btn-height;
	background-color: $syg-sp-menu-btn-bgcolor;
	border-radius: $syg-sp-menu-btn-radius;

	@include syg-media($syg-media-large){
		display: none;
	}

	// 文字を入れる場合
	.#{$syg-prefix}p-sp-menu-btn__text{
		position: absolute;
		top: $syg-sp-menu-btn-text-top;
		width: 100%;
		text-align: center;
		font-size: $syg-sp-menu-btn-text-size;
		color: $syg-sp-menu-btn-text-color;
		line-height: 1;
	}

	// 三本線
	span{
		position: absolute;
		display: inline-block;
		left: $syg-sp-menu-btn-line-left;
		width: $syg-sp-menu-btn-line-width;
		height: $syg-sp-menu-btn-line-height;
		background-color: $syg-sp-menu-btn-line-bgcolor;
		transition: $syg-sp-menu-btn-line-transition;

		&:nth-of-type(1){
			top: $syg-sp-menu-btn-line-top-1;
			transform-origin: $syg-sp-menu-btn-line-origin-1;
		}
		&:nth-of-type(2){
			top: $syg-sp-menu-btn-line-top-2;
		}
		&:nth-of-type(3){
			top: $syg-sp-menu-btn-line-top-3;
			transform-origin: $syg-sp-menu-btn-line-origin-3;
		}
	}
	&[data-sp-menu='open']{
		background-color: $syg-sp-menu-btn-bgcolor-hover;

		span{
			background-color: $syg-sp-menu-btn-line-bgcolor-hover;

			&:nth-of-type(1){
				transform: $syg-sp-menu-btn-line-transform-1;
			}
			&:nth-of-type(2){
				opacity: 0;
			}
			&:nth-of-type(3){
				transform: $syg-sp-menu-btn-line-transform-3;
			}
		}
	}
}
