


// TABLE基本設定
%#{$syg-prefix-ph}ph-table-base{

	margin: $syg-table-margin;

	th,td{
		font-weight: normal;
		border: none;
	}
}

// TABLE基本スタイル
%#{$syg-prefix-ph}ph-table-common{
	th,td{
		padding: $syg-table-padding;
		vertical-align: top;
		text-align: left;
	}
	th{
		color: $syg-table-th-color;
		background-color: $syg-table-th-bgcolor;
	}
	td{
		color: $syg-table-td-color;
		background-color: $syg-table-td-bgcolor;
	}
}

// 上下枠線
%#{$syg-prefix-ph}ph-table-border-tb{
	border-top: $syg-table-border-tb;
	border-bottom: $syg-table-border-tb;
}

// 中の枠線
%#{$syg-prefix-ph}ph-table-border{
	th,td{
		border-top: $syg-table-border-inner;
	}
	tr:last-child{
		th, td{
			border-bottom: $syg-table-border-inner;
		}
	}
}

// ヘッダ太文字
%#{$syg-prefix-ph}ph-table-th-bold{
	th{
		font-weight: bold;
	}
}

// ヘッダ背景色
%#{$syg-prefix-ph}ph-table-th-bgcolor{
	th{ background-color: $syg-table-th-bgcolor; }
}

// 縦方向にストライプ
%#{$syg-prefix-ph}ph-table-stripe-v{
	tr:nth-child(odd){
		th{
			background-color: $syg-table-th-bgcolor-odd;
		}
		td{
			background-color: $syg-table-td-bgcolor-odd;
		}
	}
	tr:nth-child(even){
		th{
			background-color: $syg-table-th-bgcolor-even;
		}
		td{
			background-color: $syg-table-td-bgcolor-even;
		}
	}
}

// 横方向にストライプ
%#{$syg-prefix-ph}ph-table-stripe-h{
	th:nth-child(odd){
		background-color: $syg-table-th-bgcolor-odd;
	}
	td:nth-child(odd){
		background-color: $syg-table-td-bgcolor-odd;
	}
	th:nth-child(even){
		background-color: $syg-table-th-bgcolor-even;
	}
	td:nth-child(even){
		background-color: $syg-table-td-bgcolor-even;
	}
}
