

.p-frame-bd-brown{
	border: solid 1px rgba($syg-color-brown, .3);
	border-radius: 4px;
}

.p-frame-bg-white{
	background-color: #fff;
	border-radius: 4px;
}

.p-frame-bg-red{
	background-color: $syg-color-red;
	border-radius: 4px;
	color: #fff;
}