@charset "UTF-8";

// サイズ指定
// 主に画像サイズで使う


@mixin create-u-sz( $media ){
	@each $key, $size in $syg-sizes{

		.#{$syg-prefix}u-sz-w#{$key}#{$media}{
			width: $size !important;
			height: auto !important;
		}
		.#{$syg-prefix}u-sz-h#{$key}#{$media}{
			height: $size !important;
			width: auto !important;
		}
	}
}

@include create-u-sz( '' );
// タブレット
@include syg-media( $syg-media-tablet ){
	@include create-u-sz( '-tbl' );
}
// スマホ
@include syg-media( $syg-media-mobile ){
	@include create-u-sz( '-sp' );
}



