
$work-margin-pc: 30px;
$work-margin-sp: 1rem;

.work-list{
	@include syg-media($syg-media-large){
		margin-left: -$work-margin-pc;
	}
	@include syg-media($syg-media-mobile){
		margin-left: -$work-margin-sp;
	}
}

.work{
	@include syg-media($syg-media-large){
		padding-left: $work-margin-pc;
	}
	@include syg-media($syg-media-mobile){
		padding-left: $work-margin-sp;
	}
}

.work-0{
	order: 0;
	@include syg-media($syg-media-large){
		order: 1;
	}
}
.work-1{
	order: 1;
	@include syg-media($syg-media-large){
		order: 2;
	}
}
.work-2{
	order: 2;
	@include syg-media($syg-media-large){
		order: 0;
	}
}
