@charset "UTF-8";


.p-return-up{
	z-index: 100;
	position: fixed;
	// right: 20px;
	right: 50%;
	margin-right: -30px;
	bottom: 0px;
	width: 60px;
	height: 60px;
	border: none;
	// border-radius: 50%;
	// background-color: #000;
	color: #fff;
	font-size: 30px;
	line-height: 0;
	// opacity: .6;
	@include display(flex);
	@include align-items(center);
	@include justify-content(center);

	transform: translateY(100px);
	transition: all .2s ease;

	@include syg-media( $syg-media-mobile ){
		// right: calc( 50% - 30px );
	}

	// 三角を作る
	&:before{
		content: "";
		position: absolute;
		bottom: 0;
		left: calc( 50% - 50px );
		width: 0;
		height: 0;
		border-bottom: solid 50px #000;
		border-left: solid 50px transparent;
		border-right: solid 50px transparent;
		transition: border-color .2s linear;
	}


	// イベント反応
	&:hover, &:active, &:focus{
		color: #fff;
	}
	&:hover{
		&:before{
			@include syg-media( $syg-media-large ){
				border-bottom-color: $syg-color-red;
			}
		}
	}
	&:active{
		&:before{
			border-bottom-color: $syg-color-red;
		}
	}

	&.ccl-amount_top{
		transform: translateY(60px);
	}
	&.ccl-amount_not-top{
		transform: translateY(0);
	}
	i{
		z-index: 1;
		position: relative;
	}
}


