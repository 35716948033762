@charset "UTF-8";

// プロジェクトで追加したもの
// $color-key: #dc000a;

// 色
$syg-color-transparent: transparent;
$syg-color-black: #000;
$syg-color-white: #fff;
$syg-color-red: #d53212;
$syg-color-pink: #d68983;
$syg-color-perple: #8f62d7;
$syg-color-blue: #3678cb;
$syg-color-aqua: #d4f3ff;
$syg-color-green: #55b6c8;
$syg-color-lime: #65e68d;
$syg-color-yellow: #ffdf2d;
$syg-color-orange: #f6ab00;
$syg-color-brown: #5b351b;
$syg-color-darkgray: #666;
$syg-color-gray: #ccc;
$syg-color-lightgray: #f5f5f5;

// 色：リンク
$syg-color-link: $syg-color-red;
$syg-color-link-bg: transparent;
$syg-color-link-hover: #000;


// 上記で指定した色をベースに、暗い色、明るい色も用意する
$syg-colors: (
	transparent: (
		$syg-color-transparent,
		$syg-color-transparent,
		$syg-color-transparent
	),
	black: (
		darken($syg-color-black, 30%),
		$syg-color-black,
		lighten($syg-color-black, 30%)
	),
	white: (
		darken( $syg-color-white, 30% ),
		$syg-color-white,
		lighten( $syg-color-white, 30% )
	),
	red: (
		darken( $syg-color-red, 30% ),
		$syg-color-red,
		lighten( $syg-color-red, 30% )
	),
	pink: (
		darken( $syg-color-pink, 30% ),
		$syg-color-pink,
		lighten( $syg-color-pink, 30% )
	),
	perple: (
		darken( $syg-color-perple, 30% ),
		$syg-color-perple,
		lighten( $syg-color-perple, 30% )
	),
	blue: (
		darken( $syg-color-blue, 30% ),
		$syg-color-blue,
		lighten( $syg-color-blue, 30% )
	),
	aqua: (
		darken( $syg-color-aqua, 30% ),
		$syg-color-aqua,
		lighten( $syg-color-aqua, 30% )
	),
	green: (
		darken( $syg-color-green, 30% ),
		$syg-color-green,
		lighten( $syg-color-green, 30% )
	),
	lime: (
		darken( $syg-color-lime, 30% ),
		$syg-color-lime,
		lighten( $syg-color-lime, 30% )
	),
	yellow: (
		darken( $syg-color-yellow, 30% ),
		$syg-color-yellow,
		lighten( $syg-color-yellow, 30% )
	),
	orange: (
		darken( $syg-color-orange, 30% ),
		$syg-color-orange,
		lighten( $syg-color-orange, 30% )
	),
	brown: (
		darken( $syg-color-brown, 30% ),
		$syg-color-brown,
		lighten( $syg-color-brown, 30% )
	),
	darkgray: (
		darken( $syg-color-darkgray, 30% ),
		$syg-color-darkgray,
		lighten( $syg-color-darkgray, 30% )
	),
	gray: (
		darken( $syg-color-gray, 30% ),
		$syg-color-gray,
		lighten( $syg-color-gray, 30% )
	),
	lightgray: (
		darken( $syg-color-lightgray, 30% ),
		$syg-color-lightgray,
		lighten( $syg-color-lightgray, 30% )
	)
);



