@charset "UTF-8";

// 上下マージン
$syg-margin-xs: 4px !default;
$syg-margin-s: 8px !default;
$syg-margin-n: 16px !default;
$syg-margin-m: 24px !default;
$syg-margin-l: 32px !default;
$syg-margin-xl: 48px !default;

$syg-margins: (
	none: 0,
	xs: $syg-margin-xs,
	s: $syg-margin-s,
	n: $syg-margin-n,
	m: $syg-margin-m,
	l: $syg-margin-l,
	xl: $syg-margin-xl,
	bs: $syg-base-margin-bottom
) !default;