
.p-btn-frame{
	display: inline-block;
	position: relative;
	@include border-image( url("#{$syg-path-img}frame_line_01.png") 6 repeat );
	border-width: 6px;
	background-color: transparent;
	color: #000;
}

.p-btn-logo{
	width: 100%;
	height: 90px;
	background-color: #fff;
	text-align: center;
	font-size: $syg-font-size-s;
	@include syg-media($syg-media-mobile){
		display: none;
	}
}

.p-btn-visual{
	overflow: hidden;

	img{
		transition: all .2s ease;
	}

	&:hover{
		img{
			transform: scale(1.03);
		}
	}
}