@charset "UTF-8";

// ソーシャルボタン

// ボタン間隔
$syg-share-btn-margin: 0 2px;

// 丸ボタン
$syg-share-circle-size-pc: 35px;
$syg-share-circle-size-sp: 40px;
$syg-share-circle-font-size-pc: 24px;
$syg-share-circle-font-size-sp: 28px;

// ■ボタン
$syg-share-box-w-pc: 35px;
$syg-share-box-h-pc: 35px;
$syg-share-box-w-sp: 40px;
$syg-share-box-h-sp: 40px;
$syg-share-box-font-size-pc: 24px;
$syg-share-box-font-size-sp: 28px;
$syg-share-box-border-radius: 0;

// 色：全サービス統一の色
$syg-share-unity-color: #fff;
$syg-share-unity-color-hover: #fff;
$syg-share-unity-bg: #000;
$syg-share-unity-bg-hover: #5b351b;
$syg-share-unity-border: solid 1px #999;
$syg-share-unity-border-hover: $syg-share-unity-border;

// 色：背景がサービス色
// :hover 時のアイコンの色 normal / dark / light から選択
$syg-share-service-bg-hover: light;
$syg-share-service-color: #fff;
$syg-share-service-color-hover: #fff;
$syg-share-service-border: none;
$syg-share-service-border-hover: none;

// 色：アイコンがサービス色
// :hover 時のアイコンの色 normal / dark / light から選択
$syg-share-reverse-color-hover: normal;
$syg-share-reverse-bg: #fff;
$syg-share-reverse-bg-hover: #000;
$syg-share-reverse-border: none;
$syg-share-reverse-border-hover: none;

// 色：サービス毎
$syg-share-color-twitter: #00acee;
$syg-share-color-facebook: #4763b3;
$syg-share-color-line: #00c300;
$syg-share-color-googleplus: #ea4335;
$syg-share-color-hatena: #00b3de;
$syg-share-color-feedly: #69bf2d;
$syg-share-color-instagram: #3B719B;
$syg-share-color-pocket: #ef3e56;

$syg-share-colors: (
	tw: (
		darken( $syg-share-color-twitter, 20% ),
		$syg-share-color-twitter,
		lighten( $syg-share-color-twitter, 20%)
	),
	fb: (
		darken( $syg-share-color-facebook, 20% ),
		$syg-share-color-facebook,
		lighten( $syg-share-color-facebook, 20%)
	),
	ln: (
		darken( $syg-share-color-line, 20% ),
		$syg-share-color-line,
		lighten( $syg-share-color-line, 20%)
	),
	gp: (
		darken( $syg-share-color-googleplus, 20% ),
		$syg-share-color-googleplus,
		lighten( $syg-share-color-googleplus, 20%)
	),
	ht: (
		darken( $syg-share-color-hatena, 20% ),
		$syg-share-color-hatena,
		lighten( $syg-share-color-hatena, 20%)
	),
	fd: (
		darken( $syg-share-color-feedly, 20% ),
		$syg-share-color-feedly,
		lighten( $syg-share-color-feedly, 20%)
	),
	ig: (
		darken( $syg-share-color-instagram, 20% ),
		$syg-share-color-instagram,
		lighten( $syg-share-color-instagram, 20%)
	),
	po: (
		darken( $syg-share-color-pocket, 20% ),
		$syg-share-color-pocket,
		lighten( $syg-share-color-pocket, 20%)
	)
);


