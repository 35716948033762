@charset "UTF-8";

// シェアボタン
// 色の設定のみ

// ボタン共通設定
[class*="#{$syg-prefix}p-share-btn-"]{
	@include display( flex );
	@include justify-content( center );
	@include align-items( center );
	-webkit-font-smoothing: antialiased;
	text-decoration: none;
	border: none;
	user-select: none;
	white-space: nowrap;
	text-align: center;
	appearance: none;
	cursor: pointer;
	line-height: 1;
	padding-left: .1em;
	vertical-align: middle;
	transition: all .1s linear;
	&:hover, &:focus, &:active{
		border: none;
	}
}


// 背景がサービスの色になっているボタン
// usage: <a href="#" class=".p-share-btn-bg-tw">

@each $key, $val in $syg-share-colors{
	.#{$syg-prefix}p-share-btn-bg-#{$key}{
		color: $syg-share-service-color;
		background-color: syg-color( $key, normal, $syg-share-colors );
		border: $syg-share-service-border;
		&:hover{
			color: $syg-share-service-color-hover;
			background-color: syg-color( $key, $syg-share-service-bg-hover, $syg-share-colors );
			border: $syg-share-service-border-hover;
		}
		&:focus, &:active{
			background-color: syg-color( $key, normal, $syg-share-colors );
		}
	}
}

// アイコンがサービスの色になっているボタン
// usage: <a href="#" class=".p-share-btn-txt-tw">

@each $key, $val in $syg-share-colors{
	.#{$syg-prefix}p-share-btn-txt-#{$key}{
		color: syg-color( $key, normal, $syg-share-colors );
		background-color: $syg-share-reverse-bg;
		border: $syg-share-reverse-border;
		&:hover{
			color: syg-color( $key, $syg-share-reverse-color-hover, $syg-share-colors );
			background-color: $syg-share-reverse-bg-hover;
			border: $syg-share-reverse-border-hover;
		}
		&:focus, &:active{
			color: syg-color( $key, normal, $syg-share-colors );
			background-color: $syg-share-reverse-bg;
		}
	}
}

// 全サービス同じ色の設定
// usage: <a href="#" class=".p-share-btn-unity">

.#{$syg-prefix}p-share-btn-unity{
	color: $syg-share-unity-color;
	background-color: $syg-share-unity-bg;

	&:hover{
		color: $syg-share-unity-color-hover;
		background-color: $syg-share-unity-bg-hover;
	}
	&:focus, &:active{
		color: $syg-share-unity-bg
	}
}

// LINEボタンはスマホのみ
body.isPC{
	[class*="#{$syg-prefix}p-share-btn-"][data-ccl-share-svc="line"]{
		display: none;
	}
}




