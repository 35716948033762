@charset "UTF-8";


.#{$syg-prefix}u-target-sp,
.#{$syg-prefix}u-target-inline-sp,
.#{$syg-prefix}u-target-inlineblock-sp,
.#{$syg-prefix}u-target-tb,
.#{$syg-prefix}u-target-inline-tb,
.#{$syg-prefix}u-target-inlineblock-tb,
.#{$syg-prefix}u-target-pc,
.#{$syg-prefix}u-target-inline-pc,
.#{$syg-prefix}u-target-inlineblock-pc{
	display: none !important;
}

@each $media in pc tbl sp{
	@include syg-media( map-get($syg-medias, $media) ){
		.#{$syg-prefix}u-target-#{$media}{
			display: block !important;
		}
		.#{$syg-prefix}u-target-inline-#{$media}{
			display: inline !important;
		}
		.#{$syg-prefix}u-target-inlineblock-#{$media}{
			display: inline-block !important;
		}
	}
}
