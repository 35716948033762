@charset "UTF-8";


// fx.scss用
// l-fx__item 間の余白
$syg-flex-margin-xs: 4px;
$syg-flex-margin-s: 8px;
$syg-flex-margin-n: 16px;
$syg-flex-margin-m: 32px;
$syg-flex-margin-l: 40px;
$syg-flex-margin-xl: 60px;

$syg-flex-margins: (
	xs: $syg-flex-margin-xs,
	s: $syg-flex-margin-s,
	n: $syg-flex-margin-n,
	m: $syg-flex-margin-m,
	l: $syg-flex-margin-l,
	xl: $syg-flex-margin-xl
);

// // 分割の最大数
// $syg-flex-divide: 8;
// $syg-flex-divide-sp: 5;
