// position

.#{$syg-prefix}u-pos-abs{
	position: absolute !important;
}

.#{$syg-prefix}u-pos-rel{
	position: relative !important;
}


