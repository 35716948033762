@charset "UTF-8";

.c-fadein{
	z-index: 10000;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	@include transition(opacity, 1s linear .1s);

	&.c-fadein_start{
		opacity: 0;
	}

	.c-fadein__progress{
		position: absolute;
		left: 0;
		top: 50%;
		height: 1px;
		width: 0;
		background-color: $syg-color-red;
		@include transition( width .5s);
	}

}



