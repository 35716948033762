@charset "UTF-8";

// ボタン設定

// 背景
$syg-btn-bg: $syg-color-blue !default;
$syg-btn-bg-hover: shade( $syg-color-blue, 30% ) !default;
$syg-btn-bg-disabled: #ccc !default;

// フォント
$syg-btn-font-weight: 600 !default;

// テキスト
$syg-btn-text-col: #fff !default;
$syg-btn-text-col-hover: #fff !default;
$syg-btn-text-col-disabled: #eee !default;
$syg-btn-text-align: center !default;

// 透明度
$syg-opacity-disabled: .5 !default;

// 枠線
$syg-btn-border: none !default;
$syg-btn-border-radius: 4px !default;

// ドロップシャドウ
// .{prefix}-c-btn_shadow 用
$syg-btn-shadow: 0 -4px 0 0 $syg-color-blue inset !default;
$syg-btn-shadow-hover: none !default;

///////////////////////////
// サイズ：normal
$syg-btn-margin: 0 !default;
$syg-btn-padding: 1em 2em !default;
$syg-btn-font-size: $syg-font-size-n !default;
$syg-btn-lineheight: 1.3 !default;

// サイズ：small
$syg-btn-margin-s: 0 !default;
$syg-btn-padding-s: .5em 1em !default;
$syg-btn-font-size-s: $syg-font-size-s !default;
$syg-btn-lineheight-s: 1.2 !default;

