@charset "UTF-8";

// 再生アイコン
%#{$syg-prefix-ph}ph-mv-thumb{
	display: inline-block;
	position: relative;
	z-index: 1;
	border: none;
	overflow: hidden;

	&:after{
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform( translate( -50%, -50% ));
		box-sizing: border-box;

		@include display(flex);
		@include align-items(center);
		@include justify-content(center);
	}
	&:focus{
		outline: none;
	}
}
