@charset "UTF-8";

// flexbox の定義だけ
.l-wrapper{
	position: relative;
	@include display(flex);
	@include flex-direction(column)
	@include align-items(center);
	width: 100%;

	@include syg-media( $syg-media-large ){
		min-width: $wrapper-min-width;
	}
}


