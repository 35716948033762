
// ハンバーガーメニュー

$syg-sp-menu-btn-zindex: 1100;

// 全体
$syg-sp-menu-btn-position: fixed;
$syg-sp-menu-btn-width: 50px;
$syg-sp-menu-btn-height: 50px;
$syg-sp-menu-btn-bgcolor: transparent;
$syg-sp-menu-btn-bgcolor-hover: transparent;
$syg-sp-menu-btn-radius: 0;

// テキスト
$syg-sp-menu-btn-text-top: 37px;
$syg-sp-menu-btn-text-size: 10px;
$syg-sp-menu-btn-text-color: $syg-color-green;

// 三本線
$syg-sp-menu-btn-line-left: 11px + 3;
$syg-sp-menu-btn-line-width: 29px;
$syg-sp-menu-btn-line-height: 3px;
$syg-sp-menu-btn-line-bgcolor: $syg-color-red;
$syg-sp-menu-btn-line-bgcolor-hover: $syg-color-red;
$syg-sp-menu-btn-line-transition: all .4s ease;

// 三本線それぞれ
$syg-sp-menu-btn-line-top-1: 15px - 8;
$syg-sp-menu-btn-line-top-2: 25px - 8;
$syg-sp-menu-btn-line-top-3: 35px - 8;

// 開いている時の変形
$syg-sp-menu-btn-line-origin-1: center center;
$syg-sp-menu-btn-line-origin-3: center center;
$syg-sp-menu-btn-line-transform-1: translateY(11px) rotate(-45deg);
$syg-sp-menu-btn-line-transform-3: translateY(-9px) rotate(45deg);
