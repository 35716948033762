@charset "UTF-8";

// スクロールインでフェードインするクラス

// フェードインする時間
$syg-inView-time: 1s;

// 遅延時間
$syg-inView-delay: (
	2: .2s,
	4: .4s,
	6: .6s,
	8: .8s,
	10: 1s
);

// 移動方向の表示前位置
$syg-inView-move: (
	l: translateX(50px) translateX(0),
	r: translateX(-50px) translateX(0),
	t: translateY(20px) translateY(0),
	b: translateY(-20px) translateY(0),
);

