@charset "UTF-8";


// color
@each $key, $val in $syg-colors{
	.#{$syg-prefix}u-cl-#{$key}{
		color: syg-color( $key, normal ) !important;
	}
}

// color:hover
@each $key, $val in $syg-colors{
	.#{$syg-prefix}u-cl-hover-#{$key}{
		color: syg-color( $key, normal ) !important;
		&:hover{
			color: syg-color( $key, dark ) !important;
		}
	}
}

