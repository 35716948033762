@charset "UTF-8";

// Youtubeをレスポンシブ対応にする
// この中に youtube動画を入れる
.#{$syg-prefix}p-media-youtube{
	position: relative ;
	padding-bottom: 60% ;
	overflow: hidden ;

	iframe{
		position: absolute ;
		top: 0 ;
		left: 0 ;
		height: 100% ;
		width: 100% ;
	}
}

// Google mapをレスポンシブ対応にする
// この中に googlemapの埋め込みコードを入れる
.#{$syg-prefix}p-media-gmap{
	position: relative ;
	padding-bottom: 60% ;
	overflow: hidden ;

	iframe{
		position: absolute ;
		top: 0 ;
		left: 0 ;
		height: 100% ;
		width: 100% ;
	}
}

