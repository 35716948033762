@charset "UTF-8";

@mixin syg-button-color( $bg-out, $bg-hover, $text-out:#fff, $text-hover:#fff ){
	background-color: $bg-out;
	color: $text-out;
	&:hover, &:focus{
		background-color: $bg-hover;
		color: $text-hover;
	}
}
