@charset "UTF-8";

/////////////////////////////////
// フォントサイズ
$syg-font-size-xs:	$syg-base-font-size * .8 !default;
$syg-font-size-s:	$syg-base-font-size * .9 !default;
$syg-font-size-n:	$syg-base-font-size !default;
$syg-font-size-m:	$syg-base-font-size * 1.2 !default;
$syg-font-size-l:	$syg-base-font-size * 1.4 !default;
$syg-font-size-xl:	$syg-base-font-size * 1.7 !default;
$syg-font-size-el:	$syg-base-font-size * 2 !default;


$syg-font-sizes:(
	xs: $syg-font-size-xs,
	s: $syg-font-size-s,
	n: $syg-font-size-n,
	m: $syg-font-size-m,
	l: $syg-font-size-l,
	xl: $syg-font-size-xl,
	el: $syg-font-size-el
) !default;
