@charset "UTF-8";

// 全リスト共通
%#{$syg-prefix-ph}ph-list{
	margin-bottom: $syg-list-wrap-margin-bottom;
}

////////////////////////////////////
// リストのマーク部分をオリジナルにする時の必要部分
%#{$syg-prefix-ph}ph-list-mark-item{
	position: relative;
	padding-left: $syg-list-mark-padding-left;

	&:before{
		position: absolute;
		font-family: icomoon;
	}
}


////////////////////////////////////
// 番号付きリスト

// 番号つきリストの必要部分
%#{$syg-prefix-ph}ph-list-counter{
	counter-reset: section;
}

// 番号つきリスト＞アイテムの必要部分
%#{$syg-prefix-ph}ph-list-counter-item-base{
	list-style-type: none;
	position: relative;

	&:before{
		display: inline-block;
		counter-increment: section;
		content: counters(section, ".") ".";
	}
}

// 番号つきリスト＞アイテムの共通部分
%#{$syg-prefix-ph}ph-list-counter-item{
	padding-left: $syg-list-counter-padding-left;
	margin-bottom: $syg-list-counter-margin-bottom;

	&:before{
		width: $syg-list-counter-box-width;
		padding-right: $syg-list-counter-box-padding;
		margin-left: -$syg-list-counter-padding-left;
		text-align: right;
		font-weight: bold;
	}
}

////////////////////////////////////
// データリスト
%#{$syg-prefix-ph}ph-list-data-dt{
	margin-bottom: $syg-list-title-margin-bottom;
}
%#{$syg-prefix-ph}ph-list-data-dd{
	margin-bottom: $syg-list-data-margin-bottom;
}





