@charset "UTF-8";

// 実線
.#{$syg-prefix}u-bd-solid{
	border-style: solid !important;
}
// 破線
.#{$syg-prefix}u-bd-dashed{
	border-style: dashed !important;
}
// 点線
.#{$syg-prefix}u-bd-dotted{
	border-style: dotted !important;
}

// border-color
@each $key, $val in $syg-colors{
	.#{$syg-prefix}u-bd-#{$key}{
		border-color: syg-color($key, normal) !important;
	}
}

// border-width
@for $w from 0 through 4{
	.#{$syg-prefix}u-bd-#{$w}{
		border-width: #{$w} !important;
	}
}
