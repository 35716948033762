@charset "UTF-8";

// ボタン設定

// 背景
$syg-btn-bg: $syg-color-brown;
$syg-btn-bg-hover: shade( $syg-color-blue, 30% );
$syg-btn-bg-disabled: #ccc;

// フォント
$syg-btn-font-size: $syg-font-size-n;
$syg-btn-font-weight: 600;
$syg-btn-lineheight: 1.3;

// テキスト
$syg-btn-text-col: #fff;
$syg-btn-text-col-hover: #fff;
$syg-btn-text-col-disabled: #eee;
$syg-btn-text-align: center;

// 透明度
$syg-opacity-disabled: .5;

// 枠線
$syg-btn-border: none;
$syg-btn-border-radius: 4px;

// 余白
$syg-btn-padding: .8em 2em;
$syg-btn-margin: 0;

// ドロップシャドウ
// .{prefix}-c-btn_shadow 用
$syg-btn-shadow: 0 -4px 0 0 $syg-color-blue inset;
$syg-btn-shadow-hover: none;

