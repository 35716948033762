@charset "UTF-8";

body{
    font-family: $syg-base-font-family;
    font-size: $syg-base-font-size;
    letter-spacing: $syg-base-letter-spacing;
    line-height: $syg-base-line-height;
    color: $syg-base-font-color;
	background-color: $syg-base-bg-color;

}