@charset "UTF-8";

$syg-badge-bg-color: #000 !default;
$syg-badge-bg-color-hover: #666 !default;

$syg-badge-color: #fff !default;
$syg-badge-color-hover: #fff !default;

$syg-badge-radius: 4px !default;
$syg-badge-weit: bold !default;



