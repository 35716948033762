@charset "UTF-8";

// 非表示
.#{$syg-prefix}u-txt-hide{
	position: absolute !important;
	display: block !important;
	width: 0 !important;
	height: 0 !important;
	overflow: hidden !important;
}

// 寄せ
.#{$syg-prefix}u-txt-left{
	text-align: left !important;
}
.#{$syg-prefix}u-txt-center{
	text-align: center !important;
}
.#{$syg-prefix}u-txt-right{
	text-align: right !important;
}
.#{$syg-prefix}u-txt-justify{
	@include syg-text-justify;
}


