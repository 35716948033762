
$syg-popup-bg: rgba( #000, .5 ) !default;
$syg-popup-zindex: 10000 !default;

// スマホの上側はかならずボタン配置用のマージンを空ける
$syg-popup-padding-top-pc: 20px !default;
$syg-popup-padding-bottom-pc: 20px !default;

$syg-popup-padding-top-sp: 30px !default;
$syg-popup-padding-bottom-sp: 0 !default;

// フェード時間
$syg-popup-time-bg: .3s !default;
$syg-popup-time-content: .3s !default;

// 閉じるボタン共通
$syg-popup-close-line-bg: #fff !default;
$syg-popup-close-line-bg-hover: #syg-link-color !default;

// 閉じるボタンPC
$syg-popup-close-x-pc: 0 !default;
$syg-popup-close-y-pc: 0 !default;
$syg-popup-close-width-pc: 80px !default;
$syg-popup-close-height-pc: 80px !default;
$syg-popup-close-line-width-pc: 80px !default;
$syg-popup-close-line-height-pc: 3px !default;

// 閉じるボタンSP
$syg-popup-close-x-sp: 0 !default;
$syg-popup-close-y-sp: 0 !default;
$syg-popup-close-width-sp: 30px !default;
$syg-popup-close-height-sp: 30px !default;
$syg-popup-close-line-width-sp: 30px !default;
$syg-popup-close-line-height-sp: 3px !default;



