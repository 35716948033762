
// セレクトボックス
select{
	margin: $syg-margin-s 0;
	// @include appearance( none );
	// padding: .5em;
    font-size: $syg-font-size-n;
    border: solid 1px #ccc;
	background: #fff;
	// position: relative;
	height: 2em;
}