@charset "UTF-8";

/* バッジ *****************/
.#{$syg-prefix}p-badge{
	@extend %#{$syg-prefix-ph}ph-badge;

	background: $syg-badge-bg-color;
	color: $syg-badge-color;
	font-weight: $syg-badge-weit;

}

a.#{$syg-prefix}p-badge{
	border: none;
	text-decoration: none;
	transition: background-color .1s linear;
	@include syg-button-color(
		$syg-badge-bg-color, $syg-badge-bg-color-hover,
		$syg-badge-color, $syg-badge-color-hover
	);
}

