@charset "UTF-8";

@include syg-media( $syg-media-large ){
	.#{$syg-prefix}-u-clm_2-pc{
		@include column-count(2);
	}
	.#{$syg-prefix}-u-clm_3-pc{
		@include column-count(3);
	}
}

// column-gap
@each $key, $val in $syg-line-heights{
	.#{$syg-prefix}u-clm-gap-#{$key}{
		@include column-gap($val);
	}
}

