@charset "UTF-8";

// ベース
$syg-base-font-size: 15px;
$syg-base-font-color: #000;
$syg-base-letter-spacing: .06em;
$syg-base-font-family : $syg-font-family-mincho;
$syg-base-line-height: 1.6;
$syg-base-margin-bottom: .9em;
$syg-base-bg-color: #fff;





