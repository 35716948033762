@charset "UTF-8";


.#{$syg-prefix}u-mg-auto{
	display: block !important;
	margin-left: auto !important;
	margin-right: auto !important;
}


@mixin create-u-mg( $media ){
	@each $key, $size in $syg-margins {
		// 全体
		.#{$syg-prefix}u-mg-#{$key}#{$media}{
			margin: $size !important;
		}
		// 上下
		.#{$syg-prefix}u-mg-tb-#{$key}#{$media}{
			margin-top: $size !important;
			margin-bottom: $size !important;
		}
		// 上
		.#{$syg-prefix}u-mg-t-#{$key}#{$media}{
			margin-top: $size !important;
		}
		// 下
		.#{$syg-prefix}u-mg-b-#{$key}#{$media}{
			margin-bottom: $size !important;
		}
		// 左右
		.#{$syg-prefix}u-mg-lr-#{$key}#{$media}{
			margin-left: $size !important;
			margin-right: $size !important;
		}
		// 左
		.#{$syg-prefix}u-mg-l-#{$key}#{$media}{
			margin-left: $size !important;
		}
		// 右
		.#{$syg-prefix}u-mg-r-#{$key}#{$media}{
			margin-right: $size !important;
		}
	}
}

@include create-u-mg( '' );
// タブレット
@include syg-media( $syg-media-tablet ){
	@include create-u-mg( '-tbl' );
}
// スマホ
@include syg-media( $syg-media-mobile ){
	@include create-u-mg( '-sp' );
}


