@charset "UTF-8";

.#{$syg-prefix}u-fl-l{
	float: left !important;
}

.#{$syg-prefix}u-fl-r{
	float: right !important;
}


