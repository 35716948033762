@charset "UTF-8";

.#{$syg-prefix}blogcard{
	margin: 1.5rem 0;
	width: 100%;

	&.#{$syg-prefix}blogcard_external{
		// はてなブックマークが入るので細かい指定はしない
		border: none;
	}
	&.#{$syg-prefix}blogcard_internal{
		border: solid 1px rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		padding: 12px;

		.#{$syg-prefix}blogcard__thumbnail{
			width: 100px;
			float: right;
		}
		.#{$syg-prefix}blogcard__content{
			margin-right: 110px;
			line-height: 1.3em;
			.#{$syg-prefix}blogcard__title{
				font-size: 17px;
				font-weight: bold;
				margin-bottom: 0.4em;
				a{
					color: #3d3f44;
					border: none;
					&:hover{
						border-bottom: solid 1px #3d3f44;
					}
				}
			}
			.#{$syg-prefix}blogcard__excerpt{
				font-size: 12px;
			}
		}
		.#{$syg-prefix}blogcard__footer{
			clear: both;
			font-size: 12px;
			color: #999;
			.#{$syg-prefix}blogcard__site, .#{$syg-prefix}blogcard__hatebu, .#{$syg-prefix}blogcard__date{
				display: inline-block;
				margin-right: 4px;
			}
			.#{$syg-prefix}blogcard__favicon{
				margin-right: 4px;
				img{
					width: 16px;
				}
			}
			a{
				color: #999;
				border: none;
				&:hover{
					border-bottom: solid 1px #999;
				}
			}
		}
	}
}

