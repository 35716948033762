
// 色
// $syg-table-th-color: $syg-base-font-color;
// $syg-table-td-color: $syg-base-font-color;

$syg-table-th-bgcolor: transparent;
// $syg-table-th-bgcolor-odd: #ccc;
// $syg-table-th-bgcolor-even: #ddd;

// $syg-table-td-bgcolor: transparent;
// $syg-table-td-bgcolor-odd: #f6f6f6;
// $syg-table-td-bgcolor-even: #fff;

// $syg-table-border-tb: solid 2px #ccc; //表の上下の線
$syg-table-border-inner: solid 1px #666; // 表の内側の線

// 基本
// $syg-table-margin: 1em 0;
$syg-table-padding: 0.4em;

