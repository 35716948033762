// シェアボタン

%c-share-hishi{
	@include display( flex );

	a{
		transform: rotate( -45deg );
	}
	i{
		display: inline-block;
		transform: rotate( 45deg );
	}
}

// 菱型ボタン（PCのみ）
.c-share-hishi{
	position: fixed;
	right: 0;
	top: 40%;
	transform: translateY(-50%);
	padding: 18px 8px;
	border-radius: 6px 0 0 6px;
	@include flex-direction(column);
	@extend %c-share-hishi;
	background-image: url("#{$syg-path-img}texture/texture_mesh.png");
	background-color: $syg-color-red;

	a{
		width: 25px;
		height: 25px;
		font-size: 18px;
	}
	li:first-child{
		margin-bottom: 15px;
	}

	// スマホ
	@include syg-media( $syg-media-mobile ){
		display: none;
	}
}

// スマホヘッダ用
.c-share-hishi-header{
	@extend %c-share-hishi;
	@include justify-content(center);
	a{
		width: 30px; height: 30px;
		font-size: 20px;
	}
	a{
		margin: 0 8px;
	}
}
