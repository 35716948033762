@charset "UTF-8";


@each $key, $val in $syg-colors{
	// bgcolor
	.#{$syg-prefix}u-bg-#{$key}{
		background-color: syg-color( $key, normal ) !important;
	}
}

