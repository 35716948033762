@charset "UTF-8";

// 写真みたいなフチを付ける。
// span などで囲って行う
.#{$syg-prefix}p-photoframe{
	display: inline-block;
	padding: $syg-img-photo-padding;
	background-color:	#fff;
	border: $syg-img-photo-border;
	z-index: 1;
}
a.#{$syg-prefix}p-photoframe:hover{
	border-color: $syg-img-photo-border-hover;
}

// 画像の幅はそのままに、内側にフチを付ける
// span などで囲って行う
.#{$syg-prefix}p-photoframe-in{
	position: relative;
	display: inline-block;

	&:after{
		content: "";
		display: inline-block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		box-shadow:
			0 0 0 $syg-img-photo-border-width $syg-img-photo-border-color inset,
			0 0 0 ($syg-img-photo-padding + 1) #fff inset;
	}
}
a.#{$syg-prefix}p-photoframe-in:hover{
	&:after{
		box-shadow:
			0 0 0 $syg-img-photo-border-width $syg-img-photo-border-hover inset,
			0 0 0 ($syg-img-photo-padding + 1) #fff inset;
	}
}

