@charset "UTF-8";


// 行間
$syg-line-height-xs: 1em !default;
$syg-line-height-s: $syg-base-line-height * .85 !default;
$syg-line-height-n: $syg-base-line-height !default;
$syg-line-height-m: $syg-base-line-height * 1.2 !default;
$syg-line-height-l: $syg-base-line-height * 1.5 !default;
$syg-line-height-xl: $syg-base-line-height * 1.8 !default;

$syg-line-heights:(
	xs: $syg-line-height-xs,
	s: $syg-line-height-s,
	n: $syg-line-height-n,
	m: $syg-line-height-m,
	l: $syg-line-height-l,
	xl: $syg-line-height-xl
) !default;