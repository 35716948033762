
// ポップアップのベース
// コンテンツのサイズは指定しない
// 各プロジェクトで下記のラッパークラスを作る
// .#{$syg-prefix}c-popup[data-popup-visible=true] .#{$syg-prefix}c-popup__content


.#{$syg-prefix}c-popup{
	position: fixed;
	z-index: $syg-popup-zindex;
	left: 0;
	top: 0;
	width: 100%;
	@include display( flex );
	@include justify-content( center );
	@include align-items( center );
	overflow: hidden;
	// 以下、変化するプロパティ
	padding-top: 0;
	opacity: 0;
	height: 0;

	&[data-popup-visible=true]{
		opacity: 1;
		height: 100%;
		@include syg-media( $syg-media-large ){
			padding-top: $syg-popup-padding-top-pc;
			padding-bottom: $syg-popup-padding-bottom-pc;
		}
		@include syg-media( $syg-media-mobile ){
			padding-top: $syg-popup-padding-top-sp;
			padding-bottom: $syg-popup-padding-bottom-sp;
		}
	}
}

// 背景
.#{$syg-prefix}c-popup{
	.#{$syg-prefix}c-popup__bg{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $syg-popup-bg;
		transition: opacity $syg-popup-time-bg linear;
		// 以下、変化するプロパティ
		opacity: 0;
	}

	&[data-popup-visible=true]{
		.#{$syg-prefix}c-popup__bg{
			opacity: 1;
		}
	}
}

// コンテンツ
.#{$syg-prefix}c-popup{
	.#{$syg-prefix}c-popup__content{
		position: relative;
		transition: transform $syg-popup-time-content ease;
		overflow: hidden;
		// 以下、変化するプロパティ
		transform: scale(.8);
	}
	&[data-popup-visible=true]{
		.#{$syg-prefix}c-popup__content{
			transform: scale(1);
		}
	}
}

// 閉じるボタン
.#{$syg-prefix}c-popup{
	.#{$syg-prefix}c-popup__close{
		position: absolute;

		// PC
		@include syg-media( $syg-media-large ){
			right: $syg-popup-close-x-pc;
			top: $syg-popup-close-y-pc;
			width: $syg-popup-close-width-pc;
			height: $syg-popup-close-height-pc;
		}
		// スマホ
		@include syg-media( $syg-media-mobile ){
			right: $syg-popup-close-x-sp;
			top: $syg-popup-close-y-sp;
			width: $syg-popup-close-width-sp;
			height: $syg-popup-close-height-sp;
		}

		&:before, &:after{
			content: "";
			display: block;
			position: absolute;
			background: $syg-popup-close-line-bg;

			@include syg-media( $syg-media-large ){
				left: calc( 50% - #{$syg-popup-close-line-width-pc / 2} );
				top: calc( 50% - #{$syg-popup-close-line-height-pc / 2} );
				width: $syg-popup-close-line-width-pc;
				height: $syg-popup-close-line-height-pc;
			}
			@include syg-media( $syg-media-mobile ){
				left: calc( 50% - #{$syg-popup-close-line-width-sp / 2} );
				top: calc( 50% - #{$syg-popup-close-line-height-sp / 2} );
				width: $syg-popup-close-line-width-sp;
				height: $syg-popup-close-line-height-sp;
			}
		}
		&:before{
			transform: rotate(45deg);
		}
		&:after{
			transform: rotate(-45deg);
		}

		&:hover:before, &:hover:after{
			background: $syg-popup-close-line-bg;
		}
	}
}



