@charset "UTF-8";

.#{$syg-prefix}p-link-noborder,
.#{$syg-prefix}p-link-noborder:hover{
	border: none !important;
	background-color: transparent !important;
	img{
		border-style: none;
	}
}

.#{$syg-prefix}p-link-fade{
	&:hover{
		opacity: .6;
	}
}

.#{$syg-prefix}p-link-scale-11{
	&:hover{
		transform: scale(1.1);
	}
}

.#{$syg-prefix}p-link-scale-105{
	&:hover{
		transform: scale(1.05);
	}
}

