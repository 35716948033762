@charset "UTF-8";

// ボタン

// ボタンの最低限設定
%#{$syg-prefix-ph}ph-btn{
	display: inline-block;
	-webkit-font-smoothing: antialiased;
	text-decoration: none;
	transition: all .1s linear;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	text-align: $syg-btn-text-align;
	appearance: none;
	cursor: pointer;
	line-height: $syg-btn-lineheight;

	// 無効
	&[data-btn-disabled='true']{
		cursor: not-allowed;
		pointer-events : none;
	}
}

// 基本的なボタンの設定
%#{$syg-prefix-ph}ph-btn-basic{
	border: $syg-btn-border;
	border-radius: $syg-btn-border-radius;
	font-weight: $syg-btn-font-weight;

	@include syg-button-color( $syg-btn-bg, $syg-btn-bg-hover, $syg-btn-text-col, $syg-btn-text-col-hover);

	// 無効
	&[data-btn-disabled='true']{
		background-color: $syg-btn-bg-disabled;
		color: $syg-btn-text-col-disabled;
		opacity: $syg-opacity-disabled;
	}
}

// 基本的なボタンサイズの指定
%#{$syg-prefix-ph}ph-btn-size-n{
	margin: $syg-btn-margin;
	padding: $syg-btn-padding;
	font-size: $syg-btn-font-size;
	line-height: $syg-btn-lineheight;
}

// 小さいサイズの指定
%#{$syg-prefix-ph}ph-btn-size-s{
	margin: $syg-btn-margin-s;
	padding: $syg-btn-padding-s;
	font-size: $syg-btn-font-size-s;
	line-height: $syg-btn-lineheight-s;
}
